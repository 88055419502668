<!--
 * @Author: peng wang
 * @Date: 2021-09-29 11:29:20
 * @LastEditTime: 2021-10-09 19:27:41
 * @Description: pagination组件
-->
<template>
  <div class="ykc-pagination" ref="ykcPage" :id="pageId" :style="`justify-content:${direct}`">
    <el-pagination
      :total="tCount"
      :page-size="pSize"
      :current-page="cPage"
      @current-change="currentChange"
      layout="prev, pager, next, jumper"></el-pagination>
    <div class="page-add">
      <div class="page-total">共{{ tCount }}条</div>
      <div class="page-size">
        每页
        <div class="input-box">
          <input
            ref="pageSizeInput"
            type="text"
            autocomplete="off"
            maxlength="3"
            class="page-size-input border-box"
            @blur="handleSizeChange"
            @keyup.enter="handleSizeChange"
            @input="changeSize" />
        </div>
        条
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'YkcPagination',
    props: {
      direct: {
        // 分页位置: flex-start/center/flex-end
        type: String,
        default: 'center',
      },
      total: {
        // 总条数
        type: Number,
        default: 0,
      },
      currentPage: {
        // 当前页
        type: Number,
        default: 1,
      },
      pageSize: {
        // 一页行数
        type: Number,
        default: 10,
      },
      isTop: {
        // 切换页数时，滚动条默认到最上面
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        pageId: `pageId-${Math.random().toString(36).substring(3, 8)}`, // 分页组件id
        cPage: this.currentPage, // 当前页
        pSize: this.pageSize, // 一页行数
      };
    },
    watch: {
      // 监听当前页改变
      currentPage(newValue) {
        this.cPage = newValue;
      },
    },
    computed: {
      // 总条数
      tCount() {
        return this.total;
      },
    },
    mounted() {
      // 初始化每页条数
      this.$refs.pageSizeInput.value = this.pSize;
      this.$nextTick(() => {
        // 替换分页文字：前往页
        const el = this.$refs.ykcPage.getElementsByClassName('el-pagination__jump')[0];
        el.setAttribute('jump-before', '跳至');
        el.setAttribute('jump-after', '页');
      });
    },
    methods: {
      /**
       * 改变每页条数
       */
      handleSizeChange() {
        let val = parseInt(this.$refs.pageSizeInput.value, 10);
        if (val === this.pSize) {
          return;
        }
        val = val || 10;
        this.pSize = val;
        this.$refs.pageSizeInput.value = val;
        let sumPage = parseInt(this.tCount / this.pSize, 10);
        if (this.tCount % this.pSize > 0) {
          sumPage += 1;
        }
        if (sumPage < this.cPage) {
          this.cPage = sumPage;
        }
        this.$emit('size-change', { size: this.pSize, cPage: this.cPage });
      },
      /**
       * 改变当前页
       * @param evt
       */
      currentChange(evt) {
        this.cPage = evt;
        if (this.isTop) {
          document.documentElement.scrollTop = 0;
        }
        if (document.getElementsByClassName('btn-next')[0]) {
          // 改变页数时，‘下一步’按钮失去焦点，防回车键重复点击
          document.getElementsByClassName('btn-next')[0].blur();
        }
        this.$emit('current-change', evt);
      },
      /**
       * 监听设置每页条数输入框 只能输入数字
       */
      changeSize() {
        const val = this.$refs.pageSizeInput.value;
        this.$refs.pageSizeInput.value = val.replace(/[^\d]/g, '');
      },
    },
  };
</script>

<style lang="scss">
  @import './index.scss';
</style>
